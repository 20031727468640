import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';


@Injectable({
    providedIn:'root'
})

/**
 * This service will make the http calls easier.
 * This methods will be used instead of using angular
 * default httpclient method so that we do not need to create new headers object,
 * and do not need to follow any syntax.
 * Just call the http functions defined here and pass the required parameters.
 */
export class HttpHandlerService {

    constructor(private http: HttpClient) {
    }

    get(url:string, headers = {}, params = {}): Observable<any> {
        return this.http.get(url,{headers: new HttpHeaders(headers) ,params:params})
    }

    post(url:string, body:any, headers = {}, params = {}): Observable<any> {
        return this.http.post(url,body,{headers: new HttpHeaders(headers) ,params:params})
    }

    put(url:string, body:any, headers = {}, params = {}): Observable<any> {
        return this.http.put(url, body,{headers: new HttpHeaders(headers) ,params:params})
    }

    patch(url:string, body:any, headers = {}, params = {}): Observable<any> {
        return this.http.patch(url, body,{headers: new HttpHeaders(headers) ,params:params})
    }

    delete(url:string, headers = {}, params = {}): Observable<any> {
        return this.http.delete(url,{headers: new HttpHeaders(headers) ,params:params});
    }

    request(method:string, url:string, body:any, headers = {}, params = {}){
        return this.http.request(method, url, {body, headers: new HttpHeaders(headers), params});
    }
}
