import { MenuItemType } from '../models/app-menu';

export const Global_Constant = {
  userInfo: 'user_info',
  userToken: 'user_token',
  dashboard: 'Dashboard',
  authRoute: 'auth',
  signinRoute: 'signin',
  dashboardRoute: 'dashboard',
  formRequiredErrorPropertyName: 'required',
  formRequiredErrorMessage: 'is required',
  formInvalidInputError: 'Invalid error',
  authenticationAPIEndpoint: 'authentication',
  qsMetricsEmbeddedEndpoint:'report/embed/metrics',
  sideNavExpandedWidth: '8rem',
  sideNavCollapsedWidth: '4rem',
  invalidUsernameOrPasswordErrorMsg:'Invalid Username Or Password.',
  quickSightDashboardContainerHeight:'100%',
  quickSightDashboardContainerWidth:'100%',
  quickSightDashboardContainerId:'dashboardContainer',
};

export const Menu_List: MenuItemType[] = [
  {
    id: 1,
    name: 'Metrics',
    fontIconName: 'dashboard_customize',
    isSelected: true,
  },
  {
    id: 2,
    name: 'FAQ',
    fontIconName: 'contact_support',
    isSelected: false,
  },
];
