import { Injectable } from '@angular/core';
import { HttpHandlerService } from './httphandler.service';
import { SignInAPIRequestBody, SignInAPIResponseBody } from '../shared/models/signin';
import { Observable } from 'rxjs';
import { Auth } from '../shared/constants/endpoints';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _httpService: HttpHandlerService) {}

  postSignInData(body: SignInAPIRequestBody): Observable<SignInAPIResponseBody> {
    return this._httpService.post(Auth.login(),{}, {Authorization:this.createAuthorizationToken(body.username,body.password)});
  }

  createAuthorizationToken(username: string, passsword: string) {
    const authToken = `${username}:${passsword}`;
    return `Basic ${btoa(authToken)}`;
  } 
}
