import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';
import { Global_Constant } from './shared/constants/global-constant';
import { noauthGuard } from './shared/guards/noauth.guard';

const routes: Routes = [
  {
    path: Global_Constant.authRoute,
    canActivate:[noauthGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Global_Constant.dashboardRoute,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: Global_Constant.authRoute,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: Global_Constant.authRoute,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
