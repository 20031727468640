import { CanActivateFn, Router } from '@angular/router';
import { local_storage } from '../constants/localstorage';
import { Global_Constant } from '../constants/global-constant';
import { inject } from '@angular/core';
import { commonMethods } from '../constants/common-methods';

export const noauthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  if (commonMethods.storage.get(Global_Constant.userToken) )
  {
  router.navigate([Global_Constant.dashboardRoute]); 
   return false;
  }
  else return true;
};
