import { AfterViewInit, Component, ContentChild, ElementRef, ViewChild } from '@angular/core';
import { Global_Constant, Menu_List } from '../../constants/global-constant';
import { MenuItemType } from '../../models/app-menu';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { CommonService } from 'src/app/services/common.service';
import { QSEmbeddedURLAPIResponseBody } from '../../models/common';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements AfterViewInit {
  menuList=Menu_List;
  isHalfExpanded: boolean = true;
  sideNavExpandedWidth=Global_Constant.sideNavExpandedWidth;
  sideNavCollapsedWidth= Global_Constant.sideNavCollapsedWidth;
  quickSightDashboardContainerId=Global_Constant.quickSightDashboardContainerId;
  quickSightDashboardContainerHeight=Global_Constant.quickSightDashboardContainerHeight;
  quickSightDashboardContainerWidth=Global_Constant.quickSightDashboardContainerWidth;
  @ViewChild('iframeContainer') iframeContainer!: ElementRef;
  dashboardID!:string;
constructor(
  public _commonService:CommonService,
  private activatedRoute: ActivatedRoute
){

}

  toggleDrawerWidth() {
    this.isHalfExpanded = !this.isHalfExpanded;
  }

  selectMenuItem(item:MenuItemType){
   this.menuList.forEach((ele:MenuItemType)=>ele.isSelected=false);
   item.isSelected=true;
  }

  loadingError = false;
  dashboard: any;


  ngAfterViewInit(): void {
    if(!environment.prod && this.activatedRoute.snapshot.queryParams){
    this.dashboardID=this.activatedRoute.snapshot.queryParams['id'];
    }
    this.getQSDashboardEmbeddedURL(); 
  }

  getQSDashboardEmbeddedURL(){
    this._commonService.getQuickSightEmbeddedURL(this.dashboardID).subscribe({
      next:(res:QSEmbeddedURLAPIResponseBody)=>{
           if(res?.data?.embedUrl){
              this.embedQSDashboard(res?.data?.embedUrl);
           } 
      }
    })
  }
 
   async embedQSDashboard(embeddedURL: any) {
    var containerDiv = document.getElementById(this.quickSightDashboardContainerId) || '';
    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: this.quickSightDashboardContainerHeight,
      width: this.quickSightDashboardContainerWidth,
      resizeHeightOnSizeChangedEvent: true,
  }
    const embeddingContext:any = await createEmbeddingContext();
    this.dashboard = embeddingContext.embedDashboard(frameOptions);
    // Iframe height is going beyond html container,hence setting iframe height dynamically.
    this.iframeContainer.nativeElement.querySelector('iframe').style.height='100%';
  }
}
