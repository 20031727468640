<mat-drawer-container class="example-container">
  <mat-drawer
    #drawer
    class="example-sidenav"
    [opened]="true"
    mode="side"
    [style.width]="
      isHalfExpanded ? sideNavCollapsedWidth : sideNavExpandedWidth
    "
  >
    <div class="drawer-container">
      <div class="dashboard-toggle-icon-container">
        <mat-icon fontIcon="menu_open" (click)="toggleDrawerWidth()" [ngClass]="{'rotate-icon':isHalfExpanded}"></mat-icon>
      </div>

      <div *ngFor="let tab of menuList" (click)="selectMenuItem(tab)" class="dashboard-container" [ngClass]="{'selected-menu-tab':tab.isSelected}">
        <mat-icon [matTooltip]="isHalfExpanded ? tab.name:''" [fontIcon]="tab.fontIconName"></mat-icon>
        <span>{{tab.name}} </span>
      </div>      
    </div>
  </mat-drawer>
  <div #iframeContainer [id]="quickSightDashboardContainerId" class="example-sidenav-content">
    <span *ngIf="_commonService.qsTimerCount<=0" class="overlay-cnt"></span>
    <span
    *ngIf="_commonService.appLoader"
    class="loader"
  ></span>
  </div>
</mat-drawer-container>
