import { inject } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { local_storage } from "./localstorage";
import { session_storage } from "./sessionstorage";

export const commonMethods={
    storage:{
        get:(key:string)=>{
            if(local_storage.get(key)){
                return local_storage.get(key);
            }else if(session_storage.get(key)){
                return session_storage.get(key);
            }
        },
        clear:()=>{
            local_storage.clear();
            session_storage.clear();
        }
    }
}