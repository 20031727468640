import { CanActivateFn, Router } from '@angular/router';
import { Global_Constant } from '../constants/global-constant';
import { inject } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const _commonService = inject(CommonService);
  if (_commonService.getStorage(Global_Constant.userToken)) return true;
  else router.navigate([`/${Global_Constant.signinRoute}`]);
  return false;
};
