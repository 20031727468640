<div class="dialog-cont">
<h2 mat-dialog-title>Timeout warning</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
    <div class="center-img">
        <img src="assets/warning.png" class="warning-sign">
    </div>
    Please refresh the page to continue.</mat-dialog-content>
<mat-dialog-actions [align]="'center'">
  <button mat-button mat-dialog-close (click)="refreshPage()">Refresh</button>
</mat-dialog-actions>
</div>
