import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { MaterialModule } from './modules/material.module';
import { ValidatorMessageComponent } from './components/validator-message/validator-message.component';
import { InactivityComponent } from './components/inactivity/inactivity.component';



@NgModule({
  declarations: [
    AppHeaderComponent,
    AppMenuComponent,
    ValidatorMessageComponent,
    InactivityComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports:[
    AppHeaderComponent,
    AppMenuComponent,
    MaterialModule,
    ValidatorMessageComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
