<div>
<h1 mat-dialog-title>Logout Confirmation</h1>
<div mat-dialog-content>Are you sure you want to logout?</div>
<div mat-dialog-actions style="margin-bottom: 1rem;margin-left: 0.7rem;">
  <button mat-stroked-button mat-dialog-close cdkFocusInitial (click)="closeDialog(false)">Cancel</button>
  <button mat-stroked-button color="primary" (click)="closeDialog(true)">Logout</button>
  <!-- <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Logout</button> -->
</div>
</div>
