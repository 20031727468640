import { Injectable} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpHandlerService } from './httphandler.service';
import { QSEmbeddedURLAPIResponseBody } from '../shared/models/common';
import { Observable, Subscription, interval, timer } from 'rxjs';
import { Dashboard } from '../shared/constants/endpoints';
import { local_storage } from '../shared/constants/localstorage';
import { session_storage } from '../shared/constants/sessionstorage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InactivityComponent } from '../shared/components/inactivity/inactivity.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  appLoader = false;
  inactivityTimerSubscription!: Subscription;
  qsTimerCount=environment.qsTimeout;

  inactivityDialogRef!: MatDialogRef<any>;
  constructor(
    private _snackBar: MatSnackBar,
    private _httpService: HttpHandlerService,
    public dialog: MatDialog 
  ) {}

  stopTimer() {
    this.inactivityDialogRef?.close();
    this.inactivityTimerSubscription?.unsubscribe();
  }
  openSnackBar(
    message: string,
    action: string = 'OK',
    config: MatSnackBarConfig = { horizontalPosition: 'center', duration: 3000 }
  ) {
    return this._snackBar.open(message, action, config);
  }

  getQuickSightEmbeddedURL(dashboardID:string | undefined): Observable<QSEmbeddedURLAPIResponseBody> {
    return this._httpService.get(Dashboard.qsEmbeddedURL(dashboardID));
  }

  getStorage(key: string) {
    if (local_storage.get(key)) {
      return local_storage.get(key);
    } else if (session_storage.get(key)) {
      return session_storage.get(key);
    }
  }

  clearStorage() {
    local_storage.clear();
    session_storage.clear();
  }

  startInactivityTimer(timeout: number): void {
    this.inactivityTimerSubscription = interval(60000).subscribe(() => {
      this.qsTimerCount--;
      if (this.qsTimerCount <= 0 && !this.inactivityDialogRef) {
        this.openInactivityDialog();
      }
    });
  }


  openInactivityDialog() {
    this.inactivityDialogRef = this.dialog.open(InactivityComponent, {
      hasBackdrop: false,
    });
    this.inactivityTimerSubscription.unsubscribe();
  }
}
