// import { UserInfo } from '../models/signin';
// import { Global_Constant } from './global-constant';

export const local_storage = {
  get: (key: string): any | null => {
    if (key) {
      // const value = localStorage.getItem(getUserKey(key));
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      } else return value;
    } else return null;
  },
  set: (key: string, value: any) => {
    if (key) {
      // localStorage.setItem(getUserKey(key), JSON.stringify(value));
      localStorage.setItem(key, JSON.stringify(value));
    }
  },

  clear:()=>{
    localStorage.clear();
  }
};
