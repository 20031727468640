import { Component } from '@angular/core';

@Component({
  selector: 'app-inactivity',
  templateUrl: './inactivity.component.html',
  styleUrls: ['./inactivity.component.scss']
})
export class InactivityComponent {

  refreshPage(): void {
    // Reload the current page
    window.location.reload();
  }

}
