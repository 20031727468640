import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from 'src/app/modules/auth/components/logout/logout.component';
import { local_storage } from '../../constants/localstorage';
import { Router } from '@angular/router';
import { Global_Constant } from '../../constants/global-constant';
import { commonMethods } from '../../constants/common-methods';
import { CommonService } from 'src/app/services/common.service';
import { UserInfo } from '../../models/signin';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent {

  userInfo:UserInfo;
  constructor(public _dialog: MatDialog,
     public _router: Router,
     public _commonService:CommonService
     ) {
      this.userInfo=this._commonService.getStorage(Global_Constant.userInfo);
     }

  logoutPrompt(): void {
    const matDialogRef = this._dialog.open(LogoutComponent, {
      enterAnimationDuration: '400ms',
      exitAnimationDuration: '600ms',
    });

    matDialogRef.afterClosed().subscribe({
      next: (resultFromDialog: boolean) => {
        if (resultFromDialog) {
          this._commonService.clearStorage();
          this._router.navigate([Global_Constant.authRoute]);
        }
      },
    });
  }
}
