import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, finalize, retry, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { Global_Constant } from '../shared/constants/global-constant';
import { Router } from '@angular/router';
import { commonMethods } from '../shared/constants/common-methods';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private _commonService: CommonService,
    public _router: Router,
    public _authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._commonService.appLoader = true;
    let config = {};
    //Add below properties to the request header
    const urlFragment = req.url.split('/');
    if (
      urlFragment[urlFragment.length - 1] !==
      Global_Constant.authenticationAPIEndpoint
    ) {
      config = {
        setHeaders: {
          Authorization: `Bearer ${this._commonService.getStorage(
            Global_Constant.userToken
          )}`,
        },
      };
    } else {
      config = {};
    }
    const newRequest = req.clone(config);
    return (
      next
        .handle(newRequest)
        //If need to handle error manually

        .pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = 'An unknown error occurred';
            this._commonService.appLoader = false;
            if (error.error instanceof ErrorEvent) {
              // Client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // Server-side error
              if (
                urlFragment[urlFragment.length - 1] ===
                Global_Constant.authenticationAPIEndpoint
              ) {
                errorMessage = `Error: ${Global_Constant.invalidUsernameOrPasswordErrorMsg}`;
              } else {
                console.log(error,error.status);
                if (error.status === 0) {
                  commonMethods.storage.clear();
                  this._router.navigate([Global_Constant.authRoute]);
                  errorMessage = `Session expired: Please login again.`;
                } else {
                  errorMessage = `Error: ${error.message}`;
                }
              }
            }

            this._commonService.openSnackBar(errorMessage);
            return throwError(() => new Error(errorMessage));
          }),
          finalize(() => {
            this._commonService.appLoader = false;
          })
        )
    );
  }
}
