<div class="header-container">
  <span class="img-container">
    <img src="assets/blue_wordmark.png" />
  </span>
  <span class="profile-container">
    <span><mat-icon matTooltip="Logout" fontSet="fas" (click)="logoutPrompt()" fontIcon="fa-right-from-bracket"></mat-icon></span>
    <span><img [matTooltip]="userInfo.firstname || userInfo.lastname?(userInfo.firstname+' '+userInfo.lastname):'Unknown user'" src="assets/user_icon.png"></span>
    
  </span>
</div>
