import { Component, Input, OnInit } from '@angular/core';
import { Global_Constant } from '../../constants/global-constant';

@Component({
  selector: 'app-validator-message',
  templateUrl: './validator-message.component.html',
  styleUrls: ['./validator-message.component.scss'],
})
export class ValidatorMessageComponent implements OnInit {
  @Input() validatorFormControl: any;
  @Input() validatorControlName: string = '';
  errorMsg = '';
  constructor() {}

  ngOnInit(): void {
    if (this.validatorFormControl?.errors) {
      this.errorMsg = this.getErrorMessageFromErrorProperty(
        Object.keys(this.validatorFormControl.errors)[0]
      );
    }
  }

  getErrorMessageFromErrorProperty(errorKey: string) {
    switch (errorKey) {
      case Global_Constant.formRequiredErrorPropertyName:
        return `${this.validatorControlName} ${Global_Constant.formRequiredErrorMessage}`;
      default:
        return Global_Constant.formInvalidInputError;
    }
  }
}
